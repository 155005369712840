import {
  ButtonBase,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  // TableRow,
} from '@mui/material';
// import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import moment from 'moment/moment';
import { Trans, useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import {
  homeTypeVariants,
  // repairTypeVariants,
  residentTypeVariants,
} from 'shared/tableColVariantsList';
import useAuth from 'hooks/useAuth';
import { useAppInfo } from 'context';
import DiscountTable from './calculation/DiscountTable';

const { forwardRef } = require('react');

const ShaxmatkaHomeDetailToPrint = forwardRef(
  (
    {
      applyDiscount,
      setApplyDiscount,
      selectedDiscount,
      setSelectedDiscount,
      formik,
      ...props
    },
    ref
  ) => {
    const [{ info }] = useAppInfo();
    const { t } = useTranslation();

    const [{ user }] = useAuth();

    const setHomeType = (item, typeArr) => {
      let result = typeArr.filter((variant) => variant.code == item);
      if (result.length > 0) {
        return t(result[0].label);
      }
      return '';
    };

    return (
      <div ref={ref} className='home-detail-to-print-wrapper p-3 bg-gray-100'>
        <div className='row-data !grid !grid-cols-3'>
          <div className='row-data-item is-start'>
            <div>{props?.objectData?.name}</div>
            <div className='helper-text  !text-[12px]'>
              {t('shaxmatka.homeDetail.residentialComplex')}
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <img
              className='h-[100px] object-contain'
              src={info?.image}
              alt='logo'
            />
          </div>
          <div className='row-data-item is-end'>
            <div className='text-end'>{info?.phone || '+998 ...'}</div>
            <div className='helper-text !text-[12px] text-end'>
              {t('shaxmatka.homeDetail.callCenter')}
            </div>
          </div>
        </div>
        <div className='divider'></div>
        <div className='row-data !grid !grid-cols-3'>
          <div className='row-data-item is-start'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.areaAll')}
            </div>
            <div>
              <NumericFormat
                value={props?.homeData?.square}
                allowNegative={false}
                displayType={'text'}
                decimalScale={2}
                className='w-full text-right'
              />{' '}
              <Trans i18nKey='common.global.meter'>
                m<sup>2</sup>
              </Trans>
            </div>
          </div>
          <div className='row-data-item is-center flex flex-col items-center justify-center'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.rooms')}
            </div>
            <div className='row-data-item is-center'>
              <ButtonBase className='home-rooms !shadow-none !bg-green-300 !rounded-sm'>
                {props?.homeData?.rooms}x
              </ButtonBase>
            </div>
          </div>
          <div className='flex justify-end items-center'>
            <div className='row-data-item is-end'>
              <div className='helper-text !text-[12px] text-center'>
                {t('shaxmatka.homeDetail.price')}
              </div>
              <div className='flex flex-col'>
                <NumericFormat
                  value={
                    props?.homeData?.calculations?.isvalute == '1'
                      ? props?.homeData?.fullPriceValute
                      : props?.homeData?.fullPriceSum
                  }
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-center'
                  suffix={
                    props?.homeData?.calculations?.isvalute == '1'
                      ? ' $'
                      : ' UZS'
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='divider'></div>
        <div className='row-data flex-wrap'>
          <div className='row-data-item'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.buildingName')}
            </div>
            <div>{props?.blockName}</div>
          </div>
          <div className='row-data-item'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.residentType')}
            </div>
            <div>
              {props?.homeData?.islive
                ? setHomeType(props?.homeData.islive, residentTypeVariants)
                : '—'}
            </div>
          </div>
          <div className='row-data-item'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.stage')}
            </div>
            <div>{props?.homeData?.stage || '—'}</div>
          </div>
          <div className='row-data-item'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.houseNumber')}
            </div>
            <div>{props?.homeData?.number || '—'}</div>
          </div>
          <div className='row-data-item'>
            <div className='helper-text !text-[12px]'>
              {t('common.fields.entrance')}
            </div>
            <div>{props?.homeData?.entrance?.name || '—'}</div>
          </div>
          <div className='row-data-item'>
            <div className='helper-text !text-[12px]'>
              {t('shaxmatka.homeDetail.position')}
            </div>
            <div>
              {props?.homeData?.status
                ? setHomeType(props?.homeData?.status, homeTypeVariants)
                : ''}
            </div>
          </div>
        </div>
        <div className='row-data flex-wrap'>
          <div className='row-data-item mt-2'>
            <div className='helper-text !text-[12px]'>
              {t('common.table.address')}
            </div>
            <div>{props?.objectData?.address || '-'}</div>
          </div>
          <div className='row-data-item mt-2'>
            <div className='helper-text text-end !text-[12px]'>
              <Trans i18nKey='contract.step.three.fields.priceForSquare'>
                1 m<sup>2</sup> narxi
              </Trans>
            </div>
            <div>
              <NumericFormat
                value={props?.homeData?.calculations?.priceForSquare}
                allowNegative={false}
                displayType={'text'}
                thousandSeparator={' '}
                decimalScale={1}
                className='w-full text-center'
                suffix={
                  props?.homeData?.calculations?.isvalute == '1' ? ' $' : ' UZS'
                }
              />
            </div>
          </div>
        </div>
        {(props?.homeData?.plan?.link ||
          props?.homeData?.plan?.link2d ||
          props?.homeData?.plan?.location) && (
          <div className='h-[500px] flex gap-2.5 items-start mt-6 px-5'>
            <div className='flex flex-col justify-between h-full w-[250px]'>
              {props?.homeData?.plan?.location && (
                <div className='row-data-item'>
                  <div className='helper-text !text-[12px] text-center mb-2'>
                    {t('shaxmatka.homeDetail.location')}
                  </div>
                  <img
                    className='w-full object-contain bg-white'
                    src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.location}`}
                    alt={props?.homeData?.plan?.name}
                  />
                </div>
              )}
              {/* {props?.homeData?.plan?.link && (
              <div className='row-data-item h-1/2 py-5'>
                <div className='helper-text !text-[12px] text-center mb-2'>
                  {t('shaxmatka.homeDetail.plan3D')}
                </div>
                <img
                  className='h-full object-contain'
                  src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.link}`}
                  alt={props?.homeData?.plan?.name}
                />
              </div>
            )} */}
              <div className='row-data-item w-full'>
                <div className='helper-text !text-[12px]'>
                  Taklif {moment().add(3, 'days').format('DD.MM.YYYY HH:mm')}{' '}
                  uchun amal qiladi. Bu ommaviy taklif emas. Eng so'nggi
                  ma'lumotlar uchun savdo ofisiga murojaat qiling.
                  <br />
                  Sizga {user?.user?.name || ''} xizmat ko’rsatdi.
                </div>
              </div>
            </div>
            {props?.homeData?.plan?.link2d && (
              <div className='row-data-item h-full w-full'>
                <div className='helper-text !text-[12px] text-center mb-2'>
                  {t('shaxmatka.homeDetail.plan2D')}
                </div>
                <img
                  className='w-full h-[calc(100%-28px)] object-contain'
                  src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.link2d}`}
                  alt={props?.homeData?.plan?.name}
                />
              </div>
            )}
          </div>
        )}
        <div className='mt-6'>
          <DiscountTable
            applyDiscount={applyDiscount}
            startPrice={props?.homeData?.calculations?.startPrice}
            setStartPrice={() => {}}
            // setStartPrice
            currency={props?.homeData?.calculations?.isvalute}
            setCurrency={() => {}}
            // setCurrency
            startPricePercentage={
              props?.homeData?.calculations?.startPricePercentage
            }
            setStartPricePercentage={() => {}}
            // setStartPricePercentage
            discount={props?.homeData?.calculations?.discount}
            setDiscount={() => {}}
            // setDiscount
            discountPercentage={
              props?.homeData?.calculations?.discountPercentage
            }
            setDiscountPercentage={() => {}}
            // setDiscountPercentage
            sellPrice={props?.homeData?.calculations?.sellPrice}
            setSellPrice={() => {}}
            // setSellPrice
            priceForMonth={props?.homeData?.calculations?.priceForMonth}
            setPriceForMonth={() => {}}
            // setPriceForMonth
            month={props?.homeData?.calculations?.month}
            setMonth={() => {}}
            // setMonth
            discountId={formik.values?.discountId || null}
            fullPrice={
              props?.homeData?.calculations?.isvalute == '1'
                ? props?.homeData?.fullPriceValute
                : props?.homeData?.fullPriceSum
            }
            setPriceForSquare={() => {}}
            // setPriceForSquare
            square={props?.homeData?.square}
            selectedDiscount={selectedDiscount}
            setSelectedDiscount={() => {}}
            // setSelectedDiscount
          />
        </div>

        {/* <div className='mt-6'>
        <TableContainer className='flex-auto base-border !shadow-none h-full'>
          <Table>
            <TableHead>
              <TableRow className='!bg-[#F9F9F9]'>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    Umumiy narx
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.sellPrice')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.startPrice')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.discount')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.month')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='!text-[10px] text-gray-500'>
                    {t('shaxmatka.homeDetail.monthlyPay')}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='overflow-hidden'>
              {props?.homeData?.calculations &&
                props?.homeData?.calculations.length > 0 &&
                props?.homeData?.calculations.map((item, index) => (
                  <TableRow
                    key={`calculation-table-row-${index}`}
                    className='!bg-white'
                  >
                    <TableCell>
                      <span className='!text-[11px]'>
                        {item?.fullPrice ? (
                          <CurrencyFormat
                            value={item?.fullPrice}
                            suffix={item?.isvalute == '1' ? ' $' : ' UZS'}
                            decimalScale={1}
                          />
                        ) : (
                          0
                        )}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        <NumericFormat
                          value={item?.sellPrice}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='w-full text-center'
                          suffix={item?.isvalute == '1' ? ' $' : ' UZS'}
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        {item?.startPrice ? (
                          <CurrencyFormat
                            value={item?.startPrice}
                            suffix={item?.isvalute == '1' ? ' $' : ' UZS'}
                            decimalScale={1}
                          />
                        ) : (
                          0
                        )}{' '}
                        (
                        <NumericFormat
                          value={item?.startPricePercentage}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={2}
                          className='w-full text-center'
                          suffix=' %'
                        />
                        )
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        {item?.discount ? (
                          <CurrencyFormat
                            value={item?.discount}
                            suffix={item?.isvalute == '1' ? ' $' : ' UZS'}
                            decimalScale={1}
                          />
                        ) : (
                          0
                        )}{' '}
                        (
                        <NumericFormat
                          value={item?.discountPercentage}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={2}
                          className='w-full text-center'
                          suffix=' %'
                        />
                        )
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>{item?.month}</span>
                    </TableCell>
                    <TableCell>
                      <span className='!text-[11px]'>
                        <NumericFormat
                          value={item?.priceForMonth}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='w-full text-center'
                          suffix={item?.isvalute == '1' ? ' $' : ' UZS'}
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </div>*/}
        {/* <div className='mt-3'> */}
        {/* <div className='row-data-item'></div>
        <div className='row-data-item'></div> */}
        {/* <div className='row-data-item'>
          <div className='helper-text !text-[12px]'>
            Taklif {moment().format('DD.MM.YYYY HH:mm')} uchun amal qiladi. Bu
            ommaviy taklif emas. Eng so'nggi ma'lumotlar uchun savdo ofisiga
            murojaat qiling.
            <br />
            Sizga {user?.user?.name || ''} xizmat ko’rsatdi.
          </div>
        </div> */}
        {/* </div> */}
      </div>
    );
  }
);

export default ShaxmatkaHomeDetailToPrint;
