import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';

export default function DiscountTable({
  applyDiscount,
  startPrice,
  currency,
  startPricePercentage,
  discount,
  discountPercentage,
  sellPrice,
  priceForMonth,
  month,
  discountId,
  fullPrice,
  setStartPrice,
  setStartPricePercentage,
  setDiscount,
  setDiscountPercentage,
  setSellPrice,
  setPriceForMonth,
  setMonth,
  setPriceForSquare,
  square,
  selectedDiscount,
  toPrint,
  setSelectedDiscount = () => {},
}) {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);

  const { isLoading, isFetching, isError, error, data } = useQuery({
    queryKey: ['/admin/discount/edit/{tabIndex}', discountId],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/discount/edit/${discountId}`
      );
      return response?.data?.data;
    },

    enabled: !hasError && !!discountId && applyDiscount,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    if (!applyDiscount) {
      setSelectedDiscount({});
    } else {
      onClick(selectedDiscount);
    }
  }, [applyDiscount, fullPrice]);

  const discountPriceDis = useCallback(
    (discount) => {
      return (fullPrice * discount) / 100;
    },
    [fullPrice]
  );

  const sellPriceDis = useCallback(
    (discount, payment) => {
      if (data?.type == 1) {
        if (payment + discount >= 100) return fullPrice;
      }
      return fullPrice * (1 - discount / 100);
    },
    [fullPrice, data?.type]
  );

  const startPriceDis = useCallback(
    (discount, payment) => {
      if (data?.type == 0) {
        return ((fullPrice - (fullPrice * discount) / 100) * payment) / 100;
      }
      if (data?.type == 1) {
        return (fullPrice * payment) / 100;
      }
    },
    [data?.type, fullPrice]
  );

  const onClick = (discount) => {
    setSelectedDiscount(discount);
    setStartPrice(startPriceDis(discount?.discount, discount?.payment));
    setStartPricePercentage(discount?.payment);
    setDiscount(discountPriceDis(discount?.discount));
    setDiscountPercentage(discount?.discount);
    setSellPrice(sellPriceDis(discount?.discount, discount?.payment));
    setPriceForMonth(
      discount?.month
        ? sellPriceDis(discount?.discount, discount?.payment) / discount?.month
        : '0'
    );
    setPriceForSquare(
      sellPriceDis(discount?.discount, discount?.payment) / square
    );
    setMonth(discount?.month);
  };

  return (
    <TableContainer className='calculation-table relative z-10 !shadow-none !base-border !bg-white'>
      <Table aria-labelledby='tableTitle'>
        <TableHead>
          <TableRow className='!bg-[#F9F9F9]'>
            <TableCell>
              <span>{t('shaxmatka.moreHomeDetails.startPrice')}</span>
            </TableCell>
            <TableCell>
              <span>{t('shaxmatka.moreHomeDetails.discount')}</span>
            </TableCell>
            <TableCell>
              <span>{t('shaxmatka.moreHomeDetails.sellPrice')}</span>
            </TableCell>
            <TableCell>
              <span>{t('shaxmatka.moreHomeDetails.month')}</span>
            </TableCell>
            <TableCell>
              <span>{t('shaxmatka.moreHomeDetails.monthlyPrice')}</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='overflow-hidden'>
          {applyDiscount ? (
            isLoading || isFetching ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{ padding: 0, paddingBottom: '50px' }}
                >
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : isError ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className='flex flex-col items-center'>
                    {error?.response?.data?.message && (
                      <span className='text-red-600 font-medium'>
                        {error?.response?.data?.message}
                      </span>
                    )}
                    {error?.response?.data?.details &&
                      error?.response?.data?.details[0]?.message && (
                        <div>
                          <span className='text-red-600 font-medium'>
                            {t('common.errors.message')}
                          </span>
                          <span>
                            {error?.response?.data?.details[0]?.message}
                          </span>
                        </div>
                      )}
                  </div>
                </TableCell>
              </TableRow>
            ) : data?.discounts?.length > 0 ? (
              data?.discounts?.map((item) => (
                <TableRow hover tabIndex={-1} onClick={() => onClick(item)}>
                  <TableCell
                    className={`cell-text ${
                      toPrint ? '!py-2 !whitespace-nowrap' : ''
                    } ${
                      selectedDiscount?.id === item?.id ? '!text-green-700' : ''
                    }`}
                  >
                    <NumericFormat
                      value={startPriceDis(item?.discount, item?.payment)}
                      allowNegative={false}
                      displayType={'text'}
                      thousandSeparator={' '}
                      decimalScale={1}
                      className='w-full text-right'
                      suffix={currency == '1' ? ' $' : ' UZS'}
                    />
                    ({item?.payment}%)
                  </TableCell>
                  <TableCell
                    className={`cell-text ${
                      toPrint ? '!py-2 !whitespace-nowrap' : ''
                    } ${
                      selectedDiscount?.id === item?.id ? '!text-green-700' : ''
                    }`}
                  >
                    <NumericFormat
                      value={discountPriceDis(item?.discount)}
                      allowNegative={false}
                      displayType={'text'}
                      thousandSeparator={' '}
                      decimalScale={1}
                      className='w-full text-right'
                      suffix={currency == '1' ? ' $' : ' UZS'}
                    />
                    ({item?.discount}%)
                  </TableCell>
                  <TableCell
                    className={`cell-text ${
                      toPrint ? '!py-2 !whitespace-nowrap' : ''
                    } ${
                      selectedDiscount?.id === item?.id ? '!text-green-700' : ''
                    }`}
                  >
                    <NumericFormat
                      value={sellPriceDis(item?.discount, item?.payment)}
                      allowNegative={false}
                      displayType={'text'}
                      thousandSeparator={' '}
                      decimalScale={1}
                      className='w-full text-right'
                      suffix={currency == '1' ? ' $' : ' UZS'}
                    />
                  </TableCell>
                  <TableCell
                    className={`cell-text ${
                      toPrint ? '!py-2 !whitespace-nowrap' : ''
                    } ${
                      selectedDiscount?.id === item?.id ? '!text-green-700' : ''
                    }`}
                  >
                    {item?.month}
                  </TableCell>
                  <TableCell
                    className={`cell-text ${
                      toPrint ? '!py-2 !whitespace-nowrap' : ''
                    } ${
                      selectedDiscount?.id === item?.id ? '!text-green-700' : ''
                    }`}
                  >
                    <NumericFormat
                      value={
                        item?.month
                          ? sellPriceDis(item?.discount, item?.payment) /
                            item?.month
                          : '0'
                      }
                      allowNegative={false}
                      displayType={'text'}
                      thousandSeparator={' '}
                      decimalScale={1}
                      className='w-full text-right'
                      suffix={currency == '1' ? ' $' : ' UZS'}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <span className='no-data-found-wrapper'>
                    <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </TableCell>
              </TableRow>
            )
          ) : (
            <TableRow hover tabIndex={-1}>
              <TableCell
                className={`cell-text !text-green-700 ${
                  toPrint ? '!py-2 !whitespace-nowrap' : ''
                }`}
              >
                <NumericFormat
                  value={startPrice}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-green-700 text-right'
                  suffix={currency == '1' ? ' $' : ' UZS'}
                />
                ({startPricePercentage}%)
              </TableCell>
              <TableCell
                className={`cell-text !text-green-700 ${
                  toPrint ? '!py-2 !whitespace-nowrap' : ''
                }`}
              >
                <NumericFormat
                  value={discount}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-green-700 text-right'
                  suffix={currency == '1' ? ' $' : ' UZS'}
                />
                ({discountPercentage}%)
              </TableCell>
              <TableCell
                className={`cell-text ${
                  toPrint ? '!py-2 !whitespace-nowrap' : ''
                }`}
              >
                <NumericFormat
                  value={sellPrice}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-green-700 text-right'
                  suffix={currency == '1' ? ' $' : ' UZS'}
                />
              </TableCell>
              <TableCell
                className={`cell-text !text-green-700 ${
                  toPrint ? '!py-2 !whitespace-nowrap' : ''
                }`}
              >
                {month}
              </TableCell>
              <TableCell
                className={`cell-text ${
                  toPrint ? '!py-2 !whitespace-nowrap' : ''
                }`}
              >
                <NumericFormat
                  value={priceForMonth}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-green-700 text-right'
                  suffix={currency == '1' ? ' $' : ' UZS'}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
