import { t } from 'i18next';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

export default memo(function CrmVidgets() {
  return (
    <div>
      <div className='border-b p-4'>{t('crm.vidgets.title')}</div>
      <div>
        <div className='p-2'>
          <Link className='no-underline' to={'/admin/shaxmatka'}>
            <div className='bg-white rounded-sm p-4 flex justify-between items-center'>
              <div className='flex justify-between items-center gap-2'>
                <i className='bi bi-house-check-fill text-base-color' />
                <span className='text-[14px]'>
                  {t('crm.vidgets.selectHome')}
                </span>
              </div>
              <i className='bi bi-arrow-right text-base-color' />
            </div>
          </Link>
          <div className='bg-white rounded-sm p-4 flex justify-between items-center mt-3 cursor-pointer'>
            <div className='flex justify-between items-center gap-2'>
              <i className='bi bi-caret-down-square-fill text-base-color' />
              <span className='text-[14px]'>{t('crm.vidgets.autoSMS')}</span>
            </div>
            <i className='bi bi-chevron-right text-base-color' />
          </div>
        </div>
      </div>
    </div>
  );
});
