import Shaxmatka2PadezdItem from './Shaxmatka2PodezItem';

const Shaxmatka2BlockItem = ({
  blockIndex,
  blockItem,
  toggleSelectionItem = () => {},
  settingsMenuValue = '',
  isEditing,
}) => {
  const block = {
    id: blockItem?.id,
    name: blockItem?.name,
    objects_id: blockItem?.objects_id,
    room_number: blockItem?.room_number,
  };
  return (
    <div>
      <div className='mt-2 mb-1 ml-9 leading-4'>
        <span className='font-medium text-base text-line-1'>
          {blockItem?.name}
        </span>
      </div>
      <div className='sheet-column flex gap-0'>
        {blockItem &&
          blockItem?.padezd?.length > 0 &&
          blockItem?.padezd.map((padezd, index) => (
            <div
              className='sheet-column'
              key={`block-${padezd?.id}-columns`}
              id={`block-${padezd?.id}-columns`}
            >
              <Shaxmatka2PadezdItem
                block={block}
                isEditing={isEditing}
                padezdItem={padezd}
                padezdIndex={index}
                blockIndex={blockIndex}
                toggleSelectionItem={toggleSelectionItem}
                settingsMenuValue={settingsMenuValue}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Shaxmatka2BlockItem;
