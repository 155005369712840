import React, { Fragment } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import PaymentHistoryTable from 'components/ui/tables/PaymentHistoryTable';
import { t } from 'i18next';
import ContractPaymentSimpleListModal from './ContractPaymentSimpleListModal';

export default function ContractInfoTables({
  id,
  contractQuery,
  paymentsListQuery,
}) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <div className='bg-white p-5 rounded-[15px]'>
      <Box
        sx={{
          background: '#76768014',
          padding: '5px',
          borderRadius: '15px',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          variant='fullWidth'
          type='callsHistory'
          className='custom-tab-fulfilled'
        >
          <Tab label={t('contract.view.paymentHistory')} value={0} />
          <Tab label={t('contract.view.paymentTable')} value={1} />
        </Tabs>
      </Box>
      {/* {tabIndex == 0 && ( */}
      <div className={`mt-2 ${tabIndex != 0 ? 'hidden' : ''}`}>
        <PaymentHistoryTable dataPath={`dictionary/paymentscontract/${id}`} />
      </div>
      {/* )} */}
      {tabIndex == 1 && (
        <ContractPaymentSimpleListModal
          data={contractQuery?.data}
          paymentsListQuery={paymentsListQuery}
        />
      )}
    </div>
  );
}
