import { Box, ButtonBase, Divider, Tab, Tabs } from '@mui/material';
import CurrencyFormat from 'components/ui/text-formats/CurrencyFormat';
import { Trans, useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import {
  homeTypeVariants,
  repairTypeVariants,
  residentTypeVariants,
} from 'shared/tableColVariantsList';

import { t } from 'i18next';
import DiscountTable from '../calculation/DiscountTable';
import REPAIR_TYPE from 'shared/repairTypeList';
import CURRENCY_TYPE from 'shared/currencyTypeList';
import { formatSingle } from 'utils/formatOddDecimal';
import { useAppInfo } from 'context';

const { forwardRef, useState, useEffect, useRef } = require('react');

const EditablePrint = forwardRef(
  (
    {
      homeData,
      applyDiscount,
      setApplyDiscount,
      discountId,
      calculation,
      selectedDiscount,
      setSelectedDiscount,
      toPrint,
      tabIndex,
      setTabIndex,
      tabTwoIndex,
      setTabTwoIndex,
      phoneState,
      timeState,
      userState,
      ...props
    },
    ref
  ) => {
    const [{ info }] = useAppInfo();
    const { t } = useTranslation();
    const phoneRef = useRef();
    const timeRef = useRef();
    const userRef = useRef();

    const [fullPrice, setFullPrice] = useState(homeData?.fullPrice);
    const [sellPrice, setSellPrice] = useState(0);
    const [startPrice, setStartPrice] = useState(0);
    const [startPricePercentage, setStartPricePercentage] = useState(
      homeData?.start
    );
    const [priceForSquare, setPriceForSquare] = useState(
      homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
        ? homeData?.repaired
        : homeData?.norepaired
    );
    const [discount, setDiscount] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [month, setMonth] = useState(29);
    const [priceForMonth, setPriceForMonth] = useState(0);
    const [currency, setCurrency] = useState(
      calculation?.isvalute || CURRENCY_TYPE.SUM.code
    );

    useEffect(() => {
      if (!toPrint) {
        if (phoneRef.current)
          phoneRef.current.innerHTML = phoneState?.[0] || '';
        if (userRef.current) userRef.current.innerHTML = userState?.[0] || '';
        if (timeRef.current) timeRef.current.innerHTML = timeState?.[0] || '';
      }
    }, []);

    useEffect(() => {
      if (toPrint) {
        if (phoneRef.current)
          phoneRef.current.innerHTML = phoneState?.[0] || '';
        if (userRef.current) userRef.current.innerHTML = userState?.[0] || '';
        if (timeRef.current) timeRef.current.innerHTML = timeState?.[0] || '';
      } else {
        if (phoneRef.current) {
          phoneRef.current.addEventListener('keydown', () => {
            phoneState[1](phoneRef.current.innerHTML);
          });
          phoneRef.current.addEventListener('blur', () => {
            phoneState[1](phoneRef.current.innerHTML);
          });
        }
        if (userRef.current) {
          userRef.current.addEventListener('keydown', () => {
            userState[1](userRef.current.innerHTML);
          });
          userRef.current.addEventListener('blur', () => {
            userState[1](userRef.current.innerHTML);
          });
        }
        if (timeRef.current) {
          timeRef.current.addEventListener('keydown', () => {
            timeState[1](timeRef.current.innerHTML);
          });
          timeRef.current.addEventListener('blur', () => {
            timeState[1](timeRef.current.innerHTML);
          });
        }
      }
    }, [phoneState[0], timeState[0], userState[0]]);

    useEffect(() => {
      if (!applyDiscount) {
        setFullPrice(calculation?.fullPrice);
        setSellPrice(calculation?.fullPrice - (calculation?.discount || 0));
        setMonth(isNaN(calculation?.month) ? 29 : calculation?.month);
        setDiscount(calculation?.discount || 0);
        setDiscountPercentage(calculation?.discountPercentage || 0);
        setPriceForMonth(
          formatSingle(
            (calculation?.fullPrice -
              (calculation?.discount || 0) -
              formatSingle(
                (calculation?.fullPrice * +homeData?.start) / 100,
                2
              )) /
              (isNaN(calculation?.month) ? 29 : calculation?.month)
          )
        );
        setStartPrice(
          formatSingle((calculation?.fullPrice * +homeData?.start) / 100, 2)
        );
        setStartPricePercentage(homeData?.start);
        setPriceForSquare(
          homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
            ? homeData?.repaired
            : homeData?.norepaired
        );
      } else {
        setFullPrice(calculation?.fullPrice);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyDiscount, calculation, homeData]);

    const setHomeType = (item, typeArr) => {
      let result = typeArr.filter((variant) => variant.code == item);
      if (result.length > 0) {
        return t(result[0].label);
      }
      return '';
    };

    return (
      <div
        ref={ref}
        className='home-detail-to-print-wrapper !w-full !p-0 !min-h-0 !m-0 max-w-none'
      >
        <div
          className={`grid ${
            toPrint
              ? 'grid-cols-[170px,1fr,470px]'
              : 'grid-cols-[170px,1fr,540px]'
          }`}
        >
          <div className='flex flex-col items-center gap-2 my-3 border-r '>
            <div>
              <img className='w-[90px]' src={info?.image} alt='logo' />
            </div>
            <div className='row-data-item is-start text-center mt-10'>
              <div className='helper-text  !text-[13px]'>
                {t('shaxmatka.homeDetail.residentialComplex')}
              </div>
              <div>{props?.objectData?.name}</div>
            </div>
            {homeData?.plan?.location && (
              <div className='row-data-item py-5 mt-8 w-full'>
                <div className='helper-text !text-[13px] text-center mb-2'>
                  {t('shaxmatka.homeDetail.location')}
                </div>
                <img
                  className='w-[calc(100%-1px)] object-contain'
                  src={`${process.env.REACT_APP_BACKEND_URL}/${homeData?.plan?.location}`}
                  alt={homeData?.plan?.name}
                />
              </div>
            )}
          </div>
          {/* <div className='divider'></div> */}
          <div className='py-3 px-6'>
            <div className='bg-gray-100 py-4 px-6 rounded-xl'>
              <div className='row-data flex-wrap pb-3'>
                <div className='row-data-item grow border-r'>
                  <div className='helper-text !text-[12px]'>
                    {t('shaxmatka.homeDetail.buildingName')}
                  </div>
                  <div>{props?.blockName}</div>
                </div>
                <div className='row-data-item pl-4 grow border-r'>
                  <div className='helper-text !text-[12px]'>
                    {t('shaxmatka.homeDetail.residentType')}
                  </div>
                  <div>
                    {homeData?.islive
                      ? setHomeType(homeData.islive, residentTypeVariants)
                      : '—'}
                  </div>
                </div>
                <div className='row-data-item pl-4 grow border-r'>
                  <div className='helper-text !text-[12px]'>
                    {t('shaxmatka.homeDetail.stage')}
                  </div>
                  <div>{homeData?.stage || '—'}</div>
                </div>
                <div className='row-data-item pl-4 grow border-r'>
                  <div className='helper-text !text-[12px]'>
                    {t('shaxmatka.homeDetail.houseNumber')}
                  </div>
                  <div>{homeData?.number || '—'}</div>
                </div>
                <div className='row-data-item pl-4 grow border-r'>
                  <div className='helper-text !text-[12px]'>
                    {t('shaxmatka.homeDetail.repairType')}
                  </div>
                  <div>
                    {homeData?.isrepaired
                      ? setHomeType(homeData.isrepaired, repairTypeVariants)
                      : '—'}
                  </div>
                </div>
                <div className='row-data-item pl-4 grow'>
                  <div className='helper-text !text-[12px]'>
                    {t('shaxmatka.homeDetail.position')}
                  </div>
                  <div>
                    {homeData?.status
                      ? setHomeType(homeData?.status, homeTypeVariants)
                      : ''}
                  </div>
                </div>
              </div>
              <div className='row-data flex-wrap pt-3 border-t'>
                <div className='row-data-item grow'>
                  <div className='helper-text !text-[12px]'>
                    {t('common.table.address')}
                  </div>
                  <div>{props?.objectData?.address || '-'}</div>
                </div>
              </div>
            </div>

            {(homeData?.plan?.link2d || homeData?.plan?.location) && (
              <div
                className={`h-[500px] flex items-center justify-center mt-4`}
              >
                {homeData?.plan?.link2d && (
                  <HomePlanImgs
                    defaultValue='link2d'
                    layers={props?.layers}
                    plan={homeData?.plan}
                    isPrinting={toPrint}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                )}
                {homeData?.plan?.location && props?.layers == 2 && (
                  <HomePlanImgs
                    defaultValue='location'
                    plan={homeData?.plan}
                    isPrinting={toPrint}
                    tabIndex={tabTwoIndex}
                    setTabIndex={setTabTwoIndex}
                  />
                )}
              </div>
            )}

            <div className='mt-6'>
              <DiscountTable
                applyDiscount={applyDiscount}
                setApplyDiscount={setApplyDiscount}
                discountId={discountId}
                startPrice={startPrice}
                setStartPrice={setStartPrice}
                currency={currency}
                setCurrency={setCurrency}
                startPricePercentage={startPricePercentage}
                setStartPricePercentage={setStartPricePercentage}
                discount={discount}
                setDiscount={setDiscount}
                discountPercentage={discountPercentage}
                setDiscountPercentage={setDiscountPercentage}
                sellPrice={sellPrice}
                setSellPrice={setSellPrice}
                priceForMonth={priceForMonth}
                setPriceForMonth={setPriceForMonth}
                month={month}
                setMonth={setMonth}
                fullPrice={fullPrice}
                setPriceForSquare={setPriceForSquare}
                square={homeData?.square}
                selectedDiscount={selectedDiscount}
                setSelectedDiscount={setSelectedDiscount}
                toPrint={toPrint}
              />
            </div>
          </div>

          <div className='flex flex-col py-3 px-6 bg-gray-200'>
            <div className='row-data-row-item is-center !flex'>
              <div className='helper-text'>
                {t('shaxmatka.homeDetail.rooms')}
              </div>
              <div>
                <ButtonBase className='home-rooms !shadow-none !bg-green-300 !rounded-sm'>
                  {homeData?.rooms}x
                </ButtonBase>
              </div>
            </div>
            <div className='row-data-row-item is-start mt-3'>
              <div className='helper-text'>
                {t('shaxmatka.homeDetail.areaAll')}
              </div>
              <div>
                <NumericFormat
                  value={homeData?.square}
                  allowNegative={false}
                  displayType={'text'}
                  decimalScale={2}
                  className='w-full text-right value-text'
                />{' '}
                <Trans i18nKey='common.global.meter'>
                  m<sup>2</sup>
                </Trans>
              </div>
            </div>
            <div className='row-data-row-item is-end my-3'>
              <div className='helper-text text-center'>
                {t('shaxmatka.homeDetail.price')}
              </div>
              <div className='flex flex-col'>
                <NumericFormat
                  value={fullPrice}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-center value-text'
                  suffix={currency == '1' ? ' $' : ' UZS'}
                />
              </div>
            </div>
            <Divider />
            <div className='flex flex-col'>
              <div className='row-data-item is-start'>
                <div className='helper-text is-start !text-[16px] mt-1.5'>
                  {t('shaxmatka.homeDetail.startPrice')}
                </div>
                <span className='value-text'>
                  {startPrice ? (
                    <CurrencyFormat
                      value={startPrice}
                      suffix={currency == '1' ? ' $' : ' UZS'}
                      decimalScale={1}
                    />
                  ) : (
                    0
                  )}{' '}
                  (
                  <NumericFormat
                    value={startPricePercentage}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={2}
                    className='w-full text-center'
                    suffix=' %'
                  />
                  )
                </span>
              </div>
              <div className='row-data-item is-start'>
                <div className='helper-text is-start !text-[16px] mt-1.5'>
                  1m<sup>2</sup> narxi
                </div>
                <span className='value-text'>
                  {priceForSquare ? (
                    <CurrencyFormat
                      value={priceForSquare}
                      suffix={currency == '1' ? ' $' : ' UZS'}
                      decimalScale={1}
                    />
                  ) : (
                    0
                  )}
                </span>
              </div>
              <div className='row-data-item is-start'>
                <div className='helper-text is-start !text-[16px] mt-1.5'>
                  {t('shaxmatka.homeDetail.discount')}
                </div>
                <span className='value-text'>
                  {discount ? (
                    <CurrencyFormat
                      value={discount}
                      suffix={currency == '1' ? ' $' : ' UZS'}
                      decimalScale={1}
                    />
                  ) : (
                    0
                  )}{' '}
                  (
                  <NumericFormat
                    value={discountPercentage}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={2}
                    className='w-full text-center'
                    suffix=' %'
                  />
                  )
                </span>
              </div>
              <div className='row-data-item is-start'>
                <div className='helper-text is-start !text-[16px] mt-1.5'>
                  {t('shaxmatka.homeDetail.month')}
                </div>
                <span className='value-text'>{month}</span>
              </div>
              <div className='row-data-item is-start'>
                <div className='helper-text is-start !text-[16px] mt-1.5'>
                  {t('shaxmatka.homeDetail.monthlyPay')}
                </div>
                <span className='value-text'>
                  <NumericFormat
                    value={priceForMonth}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={1}
                    className='w-full text-center'
                    suffix={currency == '1' ? ' $' : ' UZS'}
                  />
                </span>
              </div>
              <div className='row-data-item is-start'>
                <div className='helper-text is-start !text-[16px] mt-1.5'>
                  {t('shaxmatka.homeDetail.sellPrice')}
                </div>
                <span className='value-text'>
                  <NumericFormat
                    value={sellPrice}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={1}
                    className='w-full text-center value-text'
                    suffix={currency == '1' ? ' $' : ' UZS'}
                  />
                </span>
              </div>
            </div>
            <div className='mt-auto'>
              <div className='row-data-item is-start my-3'>
                <div className='helper-text text-start'>
                  {t('shaxmatka.homeDetail.callCenter')}
                </div>
                <div
                  className='mt-1 font-medium'
                  contentEditable
                  ref={phoneRef}
                  suppressContentEditableWarning={true}
                />
              </div>
              <div className='helper-text'>
                Taklif{' '}
                <span
                  contentEditable
                  ref={timeRef}
                  suppressContentEditableWarning={true}
                />{' '}
                uchun amal qiladi. Bu ommaviy taklif emas. Eng so'nggi
                ma'lumotlar uchun savdo ofisiga murojaat qiling.
                <br />
                Sizga{' '}
                <span
                  contentEditable
                  ref={userRef}
                  suppressContentEditableWarning={true}
                />{' '}
                xizmat ko’rsatdi.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default EditablePrint;

const HomePlanImgs = ({
  plan,
  layers,
  isPrinting,
  tabIndex,
  setTabIndex,
  defaultValue,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const imgs = [];
    if (plan?.link2d) imgs?.push('link2d');
    if (plan?.location) imgs?.push('location');
    if (plan?.link) imgs?.push('link');
    setImages(imgs);
    setTabIndex(defaultValue);
  }, [plan, layers]);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div
      className={`flex flex-col justify-between h-full ${
        layers == 1 ? 'w-[400px]' : 'w-[300px]'
      } py-5 items-center`}
    >
      {tabIndex === 'link2d' && (
        <>
          <div className='helper-text !text-[12px] text-center mb-2'>
            {t('shaxmatka.homeDetail.plan2D')}
          </div>
          <img
            className={`${
              layers == 1 ? 'h-[340px]' : 'h-[250px]'
            } object-contain`}
            src={`${process.env.REACT_APP_BACKEND_URL}/${plan?.link2d}`}
            alt={plan?.name}
          />
        </>
      )}
      {tabIndex === 'location' && (
        <>
          <div className='helper-text !text-[12px] text-center mb-2'>
            {t('shaxmatka.homeDetail.location')}
          </div>
          <img
            className='h-[250px] object-contain'
            src={`${process.env.REACT_APP_BACKEND_URL}/${plan?.location}`}
            alt={plan?.name}
          />
        </>
      )}
      {tabIndex === 'link' && (
        <>
          <div className='helper-text !text-[12px] text-center mb-2'>
            {t('shaxmatka.homeDetail.plan3D')}
          </div>
          <img
            className='h-[250px] object-contain'
            src={`${process.env.REACT_APP_BACKEND_URL}/${plan?.link}`}
            alt={plan?.name}
          />
        </>
      )}
      <Box
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: '15px 15px 0 0',
          width: 'fit-content',
          opacity: isPrinting && '0',
        }}
      >
        <Tabs value={tabIndex} onChange={handleChangeTab} type='outlined'>
          {images?.map((img, i) => (
            <Tab sx={{ minWidth: 40 }} label={i + 1} value={img} key={i} />
          ))}
        </Tabs>
      </Box>
    </div>
  );
};
