/* eslint-disable eqeqeq */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import ShaxmatkaHomeDetailToPrint from './EditablePrint';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useAppInfo } from 'context';

const EditablePrintModal = ({
  open,
  setOpen,
  homeData = {},
  objectData = {},
  blockName,
  applyDiscount,
  setApplyDiscount,
  discountId,
  setData,
  selectedDiscount,
  setSelectedDiscount,
}) => {
  const [{ user }] = useAuth();
  const [{ info }] = useAppInfo();
  const [layers, setLayers] = useState('1');
  const [isPrinting, setIsPrinting] = useState(false);

  const [tabIndex, setTabIndex] = useState('link2d');
  const [tabTwoIndex, setTabTwoIndex] = useState('location');
  const { t } = useTranslation();

  // const [index, setIndex] = useState(0);

  // const handleChangeTab = (event, newValue) => {
  //   setIndex(newValue);
  // };

  const componentRef = useRef();
  const phoneState = useState(info?.phone || '+998 78 555 00 77');
  const timeState = useState(moment().format('DD.MM.YYYY HH:mm'));
  const userState = useState(user?.user?.name || '');
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrinting(false),
    onBeforePrint: () => setIsPrinting(true),
    onBeforeGetContent: () => setIsPrinting(true),
    documentTitle: `BM group ${objectData?.name}_${homeData?.blocks?.name}_${homeData?.number} uy`,
    pageStyle: `
    @media print {
      @page { size: landscape; margin: 0mm;  }
    }
    body {
        -webkit-print-color-adjust: exact;
        -webkit-print-size-adjust: exact;
    }`,
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='xl'
      scroll='body'
      PaperProps={{ sx: { maxWidth: '1420px', width: '1420px' } }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='shaxmatka-more-home-details-dialog-title'>
        <div className='flex justify-between items-center'>
          <div className='flex gap-2 items-center'>
            <i className='bi bi-columns' />
            <Select
              size='small'
              className='!h-[40px] !w-[180px]'
              sx={{ outlineColor: '#E5E9EB' }}
              options={[{ name: '1', id: 1 }]}
              value={layers}
              onChange={({ target }) => setLayers(target.value)}
            >
              <MenuItem value={'1'}>Bir ko'rinishli</MenuItem>
              <MenuItem value={'2'}>Ikki ko'rinishli</MenuItem>
            </Select>
            <Button
              startIcon={<i className='bi bi-printer text-lg mr-1' />}
              variant='base'
              size='small'
              onClick={handlePrint}
            >
              {t('common.button.print')}
            </Button>
          </div>
          <div className='close-btn-wrapper'>
            <Button
              variant='onlyIcon'
              color='primary'
              onClick={handleClose}
              sx={{ minWidth: 40, width: 45 }}
            >
              <i className='bi bi-x !text-3xl !leading-[20px]' />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {homeData && Object.keys(homeData).length > 0 && (
          <div className='shaxmatka-more-home-details-wrapper w-full !max-w-none'>
            <div className='w-full'>
              <ShaxmatkaHomeDetailToPrint
                layers={layers}
                homeData={homeData}
                objectData={objectData}
                calculation={homeData?.calculations}
                blockName={blockName}
                applyDiscount={applyDiscount}
                setApplyDiscount={setApplyDiscount}
                discountId={discountId}
                setData={setData}
                // order={index}
                selectedDiscount={selectedDiscount}
                setSelectedDiscount={setSelectedDiscount}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabTwoIndex={tabTwoIndex}
                setTabTwoIndex={setTabTwoIndex}
                phoneState={phoneState}
                timeState={timeState}
                userState={userState}
              />
            </div>

            <div className='w-full hidden'>
              <ShaxmatkaHomeDetailToPrint
                toPrint
                ref={componentRef}
                isPrinting={isPrinting}
                layers={layers}
                homeData={homeData}
                objectData={objectData}
                calculation={homeData?.calculations}
                blockName={blockName}
                applyDiscount={applyDiscount}
                setApplyDiscount={setApplyDiscount}
                discountId={discountId}
                setData={setData}
                // order={index}
                selectedDiscount={selectedDiscount}
                setSelectedDiscount={setSelectedDiscount}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                tabTwoIndex={tabTwoIndex}
                setTabTwoIndex={setTabTwoIndex}
                phoneState={phoneState}
                timeState={timeState}
                userState={userState}
              />
            </div>
          </div>
        )}
        {/* {homeData?.calculations?.length > 1 && (
          <div className='flex justify-center'>
            <Box
              sx={{
                border: 1,
                borderColor: 'divider',
                borderRadius: '15px 15px 0 0',
                width: 'fit-content',
              }}
            >
              <Tabs value={index} onChange={handleChangeTab} type='outlined'>
                {homeData?.calculations?.map((v, i) => (
                  <Tab sx={{ minWidth: 40 }} label={i + 1} value={i} key={i} />
                ))}
              </Tabs>
            </Box>
          </div>
        )} */}
      </DialogContent>
    </Dialog>
  );
};

export default memo(EditablePrintModal);
